<template>
  <div class="animated fadeIn">
    <div>
      <h4 class="mb-3">
        {{ form.name }}
      </h4>
      <div class="form-sections">
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по Контейнеру
          </h5>
          <b-row>
            <b-col md="6">
              <b-form-group
                id="numberInputGroup5"
                label="Номер"
                label-for="registration-number"
              >
                <b-form-input
                  id="registration-number"
                  v-model.trim="form.number"
                  disabled
                  type="text"
                  name="registration-number"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="registration-number"
                  @focus="rememberPreviousValue(form.number, 'number')"
                  @blur="checkContainer(form.number, 'number')"
                />
                <b-form-invalid-feedback
                  id="input1LiveFeedback5"
                >
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="nameInputGroup4"
                class="form-group-el-select"
                label="Название"
                label-for="container-make"
              >
                <b-form-input
                  id="container-name"
                  v-model.trim="form.name"
                  disabled
                  type="text"
                  name="container-name"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="container-name"
                  @focus="rememberPreviousValue(form.name, 'number')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="containerMakeInputGroup4"
                class="form-group-el-select"
                label="Тип контейнера"
                label-for="container-make"
              >
                <el-select
                  v-model="form.containerType"
                  class="form-control"
                  disabled
                  name="container-make"
                  placeholder="Начните вводить название типа"
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                >
                  <el-option
                    v-for="item in containerTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="volumeInputGroup6"
                label="Объем"
                label-for="body-volume"
              >
                <b-form-input
                  id="body-volume"
                  v-model.trim="form.volume"
                  type="number"
                  name="body-volume"
                  :disabled="true"
                  autocomplete="body-volume"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="heightInputGroup7"
                label="Высота"
                label-for="body-height"
              >
                <b-form-input
                  id="body-height"
                  v-model.trim="form.height"
                  type="number"
                  name="body-height"
                  :disabled="true"
                  autocomplete="body-height"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="widthInputGroup8"
                label="Ширина"
                label-for="body-width"
              >
                <b-form-input
                  id="body-width"
                  v-model.trim="form.width"
                  type="number"
                  name="body-width"
                  :disabled="true"
                  autocomplete="body-width"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="depthInputGroup9"
                label="Глубина"
                label-for="body-depth"
              >
                <b-form-input
                  id="body-depth"
                  v-model.trim="form.depth"
                  type="number"
                  name="body-depth"
                  :disabled="true"
                  autocomplete="body-depth"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="maximumPayloadInputGroup9"
                label="Грузоподъемность"
                label-for="body-maximumPayload"
              >
                <b-form-input
                  id="body-maximumPayload"
                  v-model.trim="form.maximumPayload"
                  type="number"
                  name="body-maximumPayload"
                  :disabled="true"
                  autocomplete="body-maximumPayload"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {containersTypesList, containerRead} from '../../services/api';

export default {
  name: 'ContainerView',
  mixins: [notifications],
  props: ['contractorId', 'containerId'],
  data() {
    return {
      containerTypes: [],
      checkContainerDone: false,
      existingContainer: false,
      isOwn: false,
      form: {
        name: '',
        number: '',
        volume: '',
        height: '',
        width: '',
        depth: '',
        maximumPayload: '',
        containerType: null,
      },
      fieldsTriggeringCheckContainerOldValues: {
        number: '',
      },
      loading: false,
    };
  },
  watch: {
    '$route'() {
      this.getContainerById(this.containerId);
    },
  },
  mounted() {
    this.containersTypesList();
    this.getContainerById(this.containerId);
  },
  methods: {
    async containersTypesList(params) {
      this.loading = true;
      const response = await containersTypesList(params);
      if (response && response.status === 200) {
        this.containerTypes = response.data.items.map((type) => ({
          value: type.id,
          text: type.name,
        }));
      }
      this.loading = false;
    },
    async getContainerById(containerId) {
      this.loading = true;
      const response = await containerRead(containerId);
      if (response && response.status === 200) {
        this.form.volume = response.data.volume;
        this.form.height = response.data.height;
        this.form.width = response.data.width;
        this.form.number = response.data.number;
        this.form.depth = response.data.depth;
        this.form.maximumPayload = response.data.maximumPayload;
        this.form.name = response.data.name;
        this.form.containerType = response.data.containerType.id;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>

